<template>
    <div v-if="this.$route.query.framed">
        <FullPage/>
    </div>
    <div v-else>
        <Main/>
    </div>
</template>

<script>
import FullPage from "@/layouts/full-page/FullPage.vue";
import Main from "@/layouts/main/Main.vue";

export default {
  name: "layout-manager",
    components: {Main, FullPage},
};
</script>
